<!-- 发表留言 -->
<template>
    <div class="PublishMsg">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">发表留言</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单 -->
        <el-form ref="form" :model="form" :rules="rules" class="form" inline label-width="120px">
            <el-form-item label="说明" prop="explain">
                <el-input  class="textArea-width"  type="textarea"
                    :rows="10" v-model="form.explain" placeholder="请输入"></el-input>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <!-- 底部 提交与取消 -->
        <div slot="footer" class="dialog-footer">
            <el-button style="background-color: #18BC9C;color: #FFFFFF;" @click="submitForm('form')">
                提 交
            </el-button>
            <el-button class="btn" @click="resetForm('form')">取 消</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    explain: "" ,//说明
                    name:""//机构名
                },
                rules: {
                    explain: [{
                        required: true,
                        message: '说明不能为空',
                        trigger: 'blur'
                    }]
                },
                info:{},
                bool:false
            }
        },
        created() {
            this.info=JSON.parse(localStorage.getItem('info'))
            console.log(this.info)
            this.form.name=this.info.name
        },
        methods: {
            goBack() { //返回
                this.$router.go(-1);
            },
            submitForm(form) { //提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        // console.log(this.form)
                        if(this.bool){
                            return
                        }   
                        this.bool=true
                        this.$request({url:'/api/mechanismexam/messageAdd',method:'POST',data:{
                            uid:localStorage.getItem('token'),
                            name:this.form.name,
                            content:this.form.explain
                        }}).then(res=>{
                            console.log(res)
                            if(res.code==1){
                                this.$message({
                                  message: '添加留言成功',
                                  type: 'success'
                                })
                              this.$router.push({
                                  path:"/onlineMsg"
                              })
                            }else{
                                this.$message({
                                  message: '添加留言失败',
                                  type: 'error'
                                })
                                this.$router.push({
                                    path:"/onlineMsg"
                                })
                            }
                        }).catch(()=>{
                            this.bool=false
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(form) { //取消
                this.goBack()
                this.$refs[form].resetFields();
            },
        }
    }
</script>

<style scoped="scoped">
    .PublishMsg {
        width: 100%;
        height: 100%;
    }

    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 59px 28px;
        display: flex;
        justify-content: space-between;
    }

    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }

    /* 表单部分 */
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        margin: 30px auto;
    }

    ::v-deep .el-form-item__label {
        color: #000000;
    }

    .el-input {
        width: 300px;
        height: 37px !important;
    }

    .el-select {
        width: 300px;
    }

    ::v-deep .el-input.is-disabled .el-input__inner {
        background-color: #FFFFFF;
    }

    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        padding-bottom: 20px;
    }

    .btn:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }
</style>
